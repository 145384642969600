import * as Sentry from "@sentry/browser";
import {fb} from "../../firebase/firebase_config";
import {updateSubscriberAfterLogin} from "./update_subsciber_after_login";

export const confirmSubscriber = () => {
    if (!fb.auth().isSignInWithEmailLink(window.location.href)) {
        const errMessage = `confirmSubscriber: invalid link at ${window.location.href}`;
        console.error(errMessage);
        Sentry.captureMessage(errMessage);
        return;
    }
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        const errMessage = `confirmSubscriber: no email detected at ${window.location.href}`;
        console.error(errMessage);
        Sentry.captureMessage(errMessage);
        email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    fb.auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result: any) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            return updateSubscriberAfterLogin(result.user.uid);
        })
        .catch((error: Error) => {
            console.error("sign in error: ", error);
            Sentry.captureException(error);
        });
};
