import * as React from "react";
import {useEffect} from "react";
import {graphql, PageProps} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {confirmSubscriber} from "../components/newsletter/actions/confirm_subscriber";
import {fb} from "../components/firebase/firebase_config";

interface IProps extends PageProps<any> {}

const ThanksPage = ({data, location}: IProps) => {
    const siteTitle = data.site.siteMetadata.title;

    useEffect(() => {
        fb.analytics().logEvent("subscriber confirm enter");
        confirmSubscriber();
    }, []);

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Thanks for subscribing" />
            <h1>Thanks!</h1>
            <p>Thank you for joining my subscribers' list.</p>
            <a href="/">Back to PragmaticReview homepage</a>
        </Layout>
    );
};

export default ThanksPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
