import {db, fb} from "../../firebase/firebase_config";

export const updateSubscriberAfterLogin = async (userId: string) => {
    const params = new URL(document.location as any).searchParams; // ANY: usage by MDN example
    const subscriberId = params.get("subscriber");
    if (!subscriberId) {
        throw new Error(`updateSubscriberAfterLogin: no subscriber ID, user: ${userId}`);
    }
    const updatedSubscriber = {
        userId,
        verifiedTimestamp: fb.firestore.FieldValue.serverTimestamp()
    };
    await db.collection("subscribers").doc(subscriberId).update(updatedSubscriber);
    fb.analytics().logEvent("subscriber confirm success");
    return true;
};
